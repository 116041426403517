.home-page {
  margin-top: 60px;

  .top-items {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  h2 {
    font-weight: 400;
  }

  h1 {
    font-family: "Gilroy-ExtraBold" !important;
  }

  .small-desc {
    font-size: 13px;
    font-weight: 500;
  }

  .sign-up {
    font-size: 20px;
    font-weight: 500;
  }

  .input-btn {
    background: white;
    width: 400px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 200px;
    padding-inline: 8px;
    margin-block: 5px;
    width: 80%;

    input {
      width: 100%;
      height: 30px;
      outline: none;
      border: none;
      border-radius: 20px;
      padding-left: 10px;
      margin-right: 10px;
      font-size: 13px;
    }

    button {
      width: 200px;
      height: 40px;
      border: none;
      border-radius: 100px;
      background-color: $btn-color;
      color: white;
      font-size: 13px;
    }
  }

  .user-count-box {
    display: flex;
    align-items: center;
    margin-top: 30px;
    gap: 20px;

    img {
      width: 160px;
    }

    .user-counts {
      font-family: "Gilroy-Bold" !important;
      font-size: 21px;
    }


    .user-desc {
      color: #80807f;
      font-weight: 500;
    }
  }

  .video-box {
    display: flex;
    align-items: center;
    justify-content: center;

    .react-player {
      video {
        border-radius: 30px;
      }
    }

    .video-main {
      background: rgb(224, 243, 125);
      background: linear-gradient(164deg, rgba(224, 243, 125, 1) 21%, rgba(176, 176, 176, 1) 73%);
      border-radius: 30px;
      padding: 10px 10px;
      width: 800px;
      height: 370px;

      .video-image-play-icon {
        position: relative;

        .video-image {
          width: 100%;
          border-radius: 20px;
        }

        .play-icon {
          .play-icon-img {
            width: 100px;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 40%;
            cursor: pointer;
          }

        }
      }

    }
  }

}