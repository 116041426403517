.top-nav-bar {
  background-color: $top-nav-color;
  padding: 10px 0px;
  // margin: 20px 70px;
  margin: 20px 0px;
  border-radius: 40px;

  .outer-box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
    .image {
      width: 40px;
    }

  }
}