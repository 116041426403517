@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: bolder;
  src: url("../../fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-style: italic;
  font-weight: bolder;
  src: url("../../fonts/Gilroy/Gilroy-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-ExtraBold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/Gilroy/Gilroy-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-ExtraBoldItalic";
  font-style: italic;
  font-weight: 800;
  src: url("../../fonts/Gilroy/Gilroy-ExtraBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-style: italic;
  font-weight: normal;
  src: url("../../fonts/Gilroy/Gilroy-SemiBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-style: italic;
  font-weight: 300;
  src: url("../../fonts/Gilroy/Gilroy-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/Gilroy/Gilroy-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-style: italic;
  font-weight: 500;
  src: url("../../fonts/Gilroy/Gilroy-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/Gilroy/Gilroy-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: bold;
  src: url("../../fonts/Gilroy/Gilroy-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-style: italic;
  font-weight: bold;
  src: url("../../fonts/Gilroy/Gilroy-SemiBoldItalic.ttf") format("truetype");
}
body {
  margin: 0;
  padding: 0;
  font-family: "Gilroy";
  background: rgb(231, 230, 235);
  background: linear-gradient(145deg, rgb(231, 230, 235) 35%, rgb(255, 255, 255) 81%);
  color: black;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin-left: 0px;
  margin-right: 0px;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Gilroy", !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #005b41;
}

::-webkit-scrollbar-thumb {
  background: white;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
  color: unset;
}

ul {
  padding: 0;
}
ul li {
  list-style: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #19212F inset !important;
  -webkit-text-fill-color: #FFFFFF;
}

.cursor {
  cursor: pointer;
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.lh-0-ls-5 {
  line-height: unset !important;
  letter-spacing: 5px;
}

.color-dark {
  color: #9C8B58;
}

.color-black {
  color: #2D2D2D;
}

.color-blue {
  color: #2C3E57;
}

.color-grey {
  color: #949494;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.go-back {
  width: 100%;
  text-align: start;
}

.line-podcast {
  /* Vector 199 */
  opacity: 0.1;
  border: 1px solid #ffffff;
}

.vertical-line {
  /* Line 1 */
  background: #ffffff;
  opacity: 0.4;
  border: 1.94758px solid #dddddd;
}
@media screen and (max-width: 820px) {
  .vertical-line {
    display: none;
  }
}

.custom-back {
  background-color: #1A212F;
  min-height: 963px;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label::after {
  border: none !important;
  background-color: transparent !important;
}

.form-floating > .form-control:not(:placeholder-shown) ~ label::after {
  border: none !important;
  background-color: transparent !important;
}

.form-floating > .form-control:focus ~ label::after {
  border: none;
  background-color: transparent !important;
}

.form-floating > .form-control:focus ~ label {
  border: none !important;
  background-color: transparent !important;
}

.form-floating > .form-control:focus {
  border: none !important;
  background-color: #19212F;
}

.vertical-line-video {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
}
@media screen and (max-width: 820px) {
  .vertical-line-video {
    display: none;
  }
}

.default-align {
  align-items: start !important;
}

.btn-theme-default {
  background: #ffffff;
  border-radius: 57px;
  /* contact Us */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border: none;
  text-transform: capitalize;
  color: #24252d;
}

.span-kansensus {
  color: #867cf3;
}

.form-floating label {
  font-size: 14px;
  color: #949494;
}
.form-floating.small-input .form-control {
  height: 43px;
}
.form-floating.small-input label {
  top: -7px;
}
.form-floating .form-control {
  border-radius: 8px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.form-floating .form-control:not(:-moz-placeholder-shown) {
  padding-top: 0;
  padding-bottom: 0;
}
.form-floating .form-control:not(:placeholder-shown) {
  padding-top: 0;
  padding-bottom: 0;
}
.form-floating .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 1;
  top: -3px;
  left: 10px;
  background: transparent;
  width: auto;
  height: auto;
  padding: 0 5px;
  color: black;
}
.form-floating .form-control:not(:placeholder-shown) ~ label {
  opacity: 1;
  top: -3px;
  left: 10px;
  background: transparent;
  width: auto;
  height: auto;
  padding: 0 5px;
  color: black;
}
.form-floating .form-control:focus ~ label {
  opacity: 1;
  top: -3px;
  left: 10px;
  width: auto;
  height: auto;
  padding: 0 5px;
  color: black;
  background: transparent;
}

.form-floating > .form-control {
  padding: 17px;
  min-height: 0px;
}

.form-floating > .form-control:focus ~ label::after {
  border: none;
  background-color: transparent;
}

.form-control {
  border: 1px solid #DDDDDD;
  outline: none;
}
.form-control:hover, .form-control:focus {
  box-shadow: none;
  border: 1px solid #FFFFFF;
  outline: none;
}

.input:-internal-autofill-selected {
  background-color: #19212f !important;
}

.password-input {
  position: relative;
}
.password-input .eye-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 16px;
  cursor: pointer;
  color: #949494;
}

.horizontol-line {
  width: 1177px;
  height: 0px;
  border: 1px solid #333333;
}

.email-button-mobile {
  display: none;
  /* Default to hidden */
}

@media only screen and (max-width: 750px) {
  .email-button-mobile {
    display: inline-block;
    /* or any other display property you prefer */
  }
}
.children {
  min-height: 340px;
}

.theme-popup {
  /* Settings */
  border-radius: 30px;
  padding: 30px;
}
.theme-popup .modal-body {
  padding: 40px;
}
.theme-popup .modal-content {
  background-color: #232D3F;
  border-radius: 30px;
}
.theme-popup .modal-content h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #FFFFFF;
  opacity: 0.96;
}
.theme-popup .modal-content p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}
.theme-popup .modal-content button {
  width: 212px;
  height: 48px;
}
.theme-popup .modal-content .btn-cancel {
  background-color: #FFFFFF;
  border: none;
  color: #f9f7f8;
  font-weight: 700;
}
.theme-popup .modal-content .btn-okay {
  background-color: #005B41;
  border: none;
}

/* VideoModal.css */
.video-theme-popup .modal-content {
  background-color: transparent;
  /* Set background color of modal content */
  border: none;
  box-shadow: none;
  padding: 0px !important;
}
.video-theme-popup .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.video-theme-popup .modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 80%;
  max-width: 800px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.video-theme-popup .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  /* Group 34770 */
  width: 25.51px;
  height: 25.51px;
}
.video-theme-popup iframe {
  width: 100%;
  height: 371px;
  border: none;
  border-radius: 8px;
}

.home-page {
  margin-top: 60px;
}
.home-page .top-items {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.home-page h2 {
  font-weight: 400;
}
.home-page h1 {
  font-family: "Gilroy-ExtraBold" !important;
}
.home-page .small-desc {
  font-size: 13px;
  font-weight: 500;
}
.home-page .sign-up {
  font-size: 20px;
  font-weight: 500;
}
.home-page .input-btn {
  background: white;
  width: 400px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 200px;
  padding-inline: 8px;
  margin-block: 5px;
  width: 80%;
}
.home-page .input-btn input {
  width: 100%;
  height: 30px;
  outline: none;
  border: none;
  border-radius: 20px;
  padding-left: 10px;
  margin-right: 10px;
  font-size: 13px;
}
.home-page .input-btn button {
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 100px;
  background-color: #97A1FF;
  color: white;
  font-size: 13px;
}
.home-page .user-count-box {
  display: flex;
  align-items: center;
  margin-top: 30px;
  gap: 20px;
}
.home-page .user-count-box img {
  width: 160px;
}
.home-page .user-count-box .user-counts {
  font-family: "Gilroy-Bold" !important;
  font-size: 21px;
}
.home-page .user-count-box .user-desc {
  color: #80807f;
  font-weight: 500;
}
.home-page .video-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-page .video-box .react-player video {
  border-radius: 30px;
}
.home-page .video-box .video-main {
  background: rgb(224, 243, 125);
  background: linear-gradient(164deg, rgb(224, 243, 125) 21%, rgb(176, 176, 176) 73%);
  border-radius: 30px;
  padding: 10px 10px;
  width: 800px;
  height: 370px;
}
.home-page .video-box .video-main .video-image-play-icon {
  position: relative;
}
.home-page .video-box .video-main .video-image-play-icon .video-image {
  width: 100%;
  border-radius: 20px;
}
.home-page .video-box .video-main .video-image-play-icon .play-icon .play-icon-img {
  width: 100px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 40%;
  cursor: pointer;
}

.page-fotter {
  max-width: 1502px !important;
  height: 383px;
  background: #000000;
  border-radius: 52px 52px 0px 0px;
  padding: 80px 60px;
}
@media screen and (max-width: 750px) {
  .page-fotter {
    height: 883px;
  }
}
@media screen and (max-width: 1250px) {
  .page-fotter .horizontol-line {
    width: 1100px;
  }
}
@media screen and (max-width: 1170px) {
  .page-fotter .horizontol-line {
    width: 1030px;
  }
}
@media screen and (max-width: 1100px) {
  .page-fotter .horizontol-line {
    width: 1000px;
  }
}
@media screen and (max-width: 820px) {
  .page-fotter .horizontol-line {
    display: none;
  }
}
.page-fotter h1 {
  margin-bottom: 15px;
  font-family: "Gilroy" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 42px;
  text-transform: capitalize;
  color: #ffffff;
}
.page-fotter ul li {
  margin-bottom: 15px;
  font-family: "Gilroy" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  /* identical to box height, or 186% */
  text-transform: capitalize;
  color: #ffffff;
}
.page-fotter .brand-col .brand-name img {
  margin-right: 10px;
  width: 50px;
}
.page-fotter .brand-col .brand-name p {
  font-family: "Gilroy" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 38.6202px;
  line-height: 46px;
  letter-spacing: -0.01em;
  color: #ffffff;
}
.page-fotter .brand-col .intro {
  width: 221px;
  height: 54px;
  font-family: "Gilroy" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* or 150% */
  text-transform: capitalize;
  color: #ffffff;
}
@media screen and (max-width: 750px) {
  .page-fotter .contact-us {
    text-align: center;
    margin-top: 20px;
  }
}
.page-fotter .contact-us .contact-us-content svg {
  margin-right: 10px;
}
.page-fotter .contact-us .contact-us-content span {
  margin-bottom: 15px;
  font-family: "Gilroy" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  /* identical to box height, or 186% */
  text-transform: capitalize;
  color: #ffffff;
}
.page-fotter .copy-right {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */
  text-transform: capitalize;
  color: #ffffff;
  margin-left: 39px;
}
@media screen and (max-width: 820px) {
  .page-fotter .copy-right {
    display: none !important;
  }
}
.page-fotter .social-media {
  margin-right: 59px;
}
@media screen and (max-width: 820px) {
  .page-fotter .social-media {
    display: none !important;
  }
}
.page-fotter .social-media svg {
  margin-left: 10px;
  cursor: pointer;
}
.page-fotter .tablet-social {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.page-fotter .tablet-social .copy-right-tablet {
  font-family: "Gilroy" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */
  text-transform: capitalize;
  color: #ffffff;
  margin-left: 39px;
}
.page-fotter .tablet-social .social-media-tablet {
  margin-right: 59px;
  margin-left: 20%;
  margin-top: 20px;
}
.page-fotter .tablet-social .social-media-tablet svg {
  margin-left: 10px;
  cursor: pointer;
}
@media screen and (max-width: 750px) {
  .page-fotter .links {
    text-align: center;
    margin-top: 20px;
  }
}

.top-nav-bar {
  background-color: white;
  padding: 10px 0px;
  margin: 20px 0px;
  border-radius: 40px;
}
.top-nav-bar .outer-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.top-nav-bar .outer-box .image {
  width: 40px;
}

.terms-and-condition h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-transform: capitalize;
  color: #323232;
}
.terms-and-condition h2 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-transform: capitalize;
  color: #867cf2 !important;
}
.terms-and-condition p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 29px;
  /* or 172% */
  text-transform: capitalize;
  color: #413d45;
}

.section-video .iframe-video {
  height: 500px;
  width: 100%;
  border-radius: 30px;
}

.contact-us label {
  font-weight: bold;
}
.contact-us input {
  font-size: 12px;
  background: white;
}
.contact-us button {
  background: rgb(147, 74, 252);
  background: linear-gradient(59deg, rgb(147, 74, 252) 25%, rgb(73, 164, 253) 92%);
  color: white;
  border-radius: 26px;
}
.contact-us button:hover {
  background: rgb(147, 74, 252);
  background: linear-gradient(59deg, rgb(147, 74, 252) 25%, rgb(73, 164, 253) 92%);
  color: white;
}
.contact-us textarea {
  font-size: 12px;
  background: white;
}/*# sourceMappingURL=style.css.map */