.contact-us {

  label {
    font-weight: bold;
  }

  input {
    font-size: 12px;
    background: white;
  }

  button {
    background: rgb(147, 74, 252);
    background: linear-gradient(59deg, rgba(147, 74, 252, 1) 25%, rgba(73, 164, 253, 1) 92%);
    color: white;
    border-radius: 26px;
  }

  button:hover {
    background: rgb(147, 74, 252);
    background: linear-gradient(59deg, rgba(147, 74, 252, 1) 25%, rgba(73, 164, 253, 1) 92%);
    color: white;
  }

  textarea {
    font-size: 12px;
    background: white;
  }
}